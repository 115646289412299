<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :items="items"
        :fields="fields"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Premios por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #imagen="{ item }">
          <img
            :src="item.imagen"
            height="70px"
            :alt="item.titulo"
          >
        </template>

        <template #titulo="{ item }">
          <td>
            <a
              class="text-info"
              @click="informacionPremio(item.acciones)"
            >{{
              item.titulo
            }}</a>
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="success"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="editPremio(item.acciones)"
            >
              Editar
            </CButton>
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="botonEliminar(item)"
            >
              Eliminar
            </CButton>
            <CButton
              color="reddit"
              shape="pill"
              size="sm"
              @click="botonAsignar(item)"
            >
              Asignar
            </CButton>
            <CModal
              id="modalAsign"
              title="Asignar premio"
              color="principal"
              :show.sync="asignModal"
            >
              <p class="text-muted">
                Asignar premio a usuario:<br>
                <select
                  id="usuarios"
                  v-model="seleccionadoUsuario"
                  class="form-select"
                  @change="showDataBeforeAssigning(seleccionadoUsuario)"
                >
                  <option
                    v-for="accion in getUsers()"
                    :key="accion.user_id"
                  >
                    {{ accion.username }}
                  </option>
                </select>
              </p>
              <p v-if="shown_user_data.videos !== undefined">
                Vídeos: 
                <ul id="user_videos">
                  <li
                    v-for="user_data in shown_user_data.videos"
                    :key="user_data.username"
                  >
                    {{ user_data.video.video_name }}
                  </li>
                </ul>
              </p>
              <p v-if="shown_user_data.semanas !== undefined">
                Número de semanas desde la creación de la cuenta: {{ shown_user_data.semanas }}
              </p>
              <p v-if="shown_user_data.unsmoked_cigarettes !== undefined">
                Cigarrillos que ha conseguido evitar: {{ shown_user_data.unsmoked_cigarettes }}
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="assignPremio(premio.acciones)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="asignModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>

            <CModal
              id="modalElim"
              title="Eliminar premio"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el premio con titulo
                {{ premio.titulo }}? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deletePremio(premio.acciones)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
      <CRow class="justify-content-center">
        <CCol
          md="9"
          class="p-4"
        >
          <CAlert
            v-model="showAssignSuccess"
            color="success"
            dismissible
            :show.sync="showAssignSuccess"
            close-button
          >
            <strong>OK</strong> El premio se le asignó al usuario {{ seleccionadoUsuario }} correctamente
          </CAlert>
          <CAlert
            color="warning"
            dismissible
            :show.sync="showAssignErrorAlert"
            close-button
          >
            <strong>AVISO</strong> El usuario {{ seleccionadoUsuario }} ya cuenta con ese premio

            <CProgress
              :max="10"
              :value="showAssignErrorAlert"
              height="3px"
              color="warning"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import {getUsersData} from "../utils/utils";

export default {
  name: "Table",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "titulo",
            label:"Título"
          },
          {
            key: "descripcion",
            label: "Descripción"
          },
          {
            key: "imagen",
            sorter: false,
            filter: false,
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
  },
  data() {
    return {
      deleteModal: false,
      premio: {},
      asignModal: false,
      usuarios: [],
      temp: 0,
      seleccionadoUsuario: {},
      seleccionadoPremio: {},
      showAssignSuccess: false,
      showAssignErrorAlert: 0,
      users_data: [],
      shown_user_data: []
    };
  },
  methods: {
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} premio - El premio sobre el que se llama el evento
     */
    botonEliminar(premio) {
      this.premio = premio;
      this.deleteModal = true;
    },
    /**
     * Este evento de click, se ejecuta al hacer click en el boton de editar de la tabla acciones
     * te lleva a la página para editar el premio.
     * 
     * @param {number} id - El Id del premio sobre el que se llama el evento
     */
    editPremio(id) {
      this.$router.push("/premio/editPremio/" + id);
    },
    /**
     * Este metodo eliminará el premio, mandando por la URL el id
     * 
     * @param {object} id - El ID del premio a eliminar
     */
    deletePremio(id) {
      const token = localStorage.token;
      axios.delete(
          process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREMIOS + id,
          {
            headers: {
              Authorization: token,
            },
          }
        ).then(() => {
          //con esto actualizamos la página para que se actualice la tabla
          location.reload();
        }).catch(function (error) {
          if (error.response) {
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
      this.deleteModal = false;
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el nombre del premio, nos llevará a la página para ver la información del premio
     * 
     * @param {number} id - El ID del premio del que mostrar su informacion
     */
    informacionPremio(id) {
      this.$router.push("/premio/infoPremio/" + id);
    },
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de Asignar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} premio - El Premio sobre el que se ha llamado el evento
     */
    botonAsignar(premio) {
      this.showAssignSuccess = false;
      this.showAssignErrorAlert = 0;
      this.premio = premio;
      this.asignModal = true;
    },
    /**
     * Este metodo, pedirá el id del usuario seleccionado en el select(que se guarda en seleccionadoUsuario mediante el v-model)
     * Después se pasa el id del premio por la URL, y la id del usuario por el body de la petición.
     * 
     * @param {number} id - El id del premio que asignarle al usuario
     */
    assignPremio(id) {
      this.asignModal = false;
      let user = this.getIdUser(this.seleccionadoUsuario);
      let token = localStorage.token;
      axios.post(
          process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_PREMIOS +
            id +
            "/assign",
          {
            user_id: user,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        ).then(() => {
          this.showAssignSuccess = true;
        }).catch((error) =>  {
          if (error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 409) {
              this.showAssignErrorAlert = 10;
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    /**
     * Con este metodo conseguimos guardar la información de los usuarios en la variable this.usuarios
     * 
     * @returns {object} Los usuarios almacenados en this.usuarios
     */
    getUsers() {
      if (this.temp > 0) {
        return this.usuarios;
      }
      let data = getUsersData();
      data.then((result) => {
        this.usuarios = result;
      });
      this.temp++;
      return this.usuarios;
    },
    async showDataBeforeAssigning(user) {
      let index = this.checkExistingData(user);

      if(index <= - 1) {
        this.getUserData(user).then((result) => {
          this.users_data.push(result);
        });
        // TODO: buscar otra solucion
        setTimeout(() => {
        index = this.checkExistingData(user);

        this.shown_user_data = this.users_data[index];
        }, 100);
      }

      index = this.checkExistingData(user);

      this.shown_user_data = this.users_data[index];
    },
    async getUserData(user) {
      let user_data = {};
      let token = localStorage.token;
      let id =  this.getIdUser(user);

      return new Promise((resolve) => {
      axios.get(
          process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_CIGARROS +
          id,
          {
            headers: {
              Authorization: token,
            },
          }
        ).then((result) => {
          user_data.unsmoked_cigarettes = result.data.unsmoked_cigarettes;
        }).catch((error) =>  {
          if (error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              user_data.unsmoked_cigarettes = "Sin datos";
            }
            if (error.response.status == 409) {
              this.showAssignErrorAlert = 10;
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });

        axios.get(
          process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_USER_VIDEOS +
          'user/'+
          id,
          {
            headers: {
              Authorization: token,
            },
          }
        ).then((result) => {
          user_data.videos =  result.data.filter(video => !(video.locked));
        }).catch((error) =>  {
          if (error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              user_data.unsmoked_cigarettes = "Sin vídeos";
            }
            if (error.response.status == 409) {
              this.showAssignErrorAlert = 10;
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });

        axios.get(
          process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_USUARIOS +
          id,
          {
            headers: {
              Authorization: token,
            },
          }
        ).then((result) => {
          if(result.data.login_date) {
            user_data.semanas = Math.floor((new Date() - new Date(result.data.login_date)) / (1000*60*60*24*7));
          } else {
            user_data.semanas = "No se tiene constancia de las semanas de este usuario";
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              user_data.semanas = "No se tiene constancia de las semanas de este usuario";
            }
            if (error.response.status == 409) {
              this.showAssignErrorAlert = 10;
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });

        user_data.username = user;

        resolve(user_data);
      });
    },
    checkExistingData(username) {
      for(let user in this.users_data)
        if(this.users_data[user].username === username) return user

      return -1;
    },
    /**
     * Con esto sacamos el id del nombre que nos pasan, ya que en teoría el username es único
     * 
     * @param {string} username - El nombre de usuario del que sacar su ID
     * 
     * @returns {number} El id del correspondiente username
     */
    getIdUser(nombre) {
      let id;
      for (let index = 0; index < this.usuarios.length; index++) {
        if (nombre == this.usuarios[index].username) {
          id = this.usuarios[index].user_id;
          break;
        }
      }
      return id;
    },
  },
};
</script>
