<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <CTableWrapper
          :items="datos"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Premios
              <CButton
                class="btn btn-success"
                size="sm"
                @click="addPremio()"
              >
                Añadir premio
              </CButton>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/TablaPremios";
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

/**
 * Función para conseguir los datos del premio 
 * 
 * @returns {Promise<object>} Una promesa que al resolverse da los datos de los premios
 */
async function getPremioData() {
  let data;
  const token = localStorage.token;
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREMIOS + "all", {
      headers: {
        Authorization: token,
      },
    })
    .then((result) => {
      data = result.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

export default {
  name: "PremioTable",
  components: { CTableWrapper },
  data() {
    return {
      datos: [],
      temp: 0,
      ip: null,
    };
  },
  created() {
    //Aquí llamamos al metodo para que se llamen a las funciones y recoger los datos
    this.recibirDatos();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    recibirDatos() {
      if (this.temp > 0) {
        return this.datos;
      }
      let data = getPremioData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.datos = [];
        let element;

        for (let index = 0; index < result.length; index++) {
          element = {};
          element.titulo = result[index].award_title;
          element.descripcion = result[index].award_description;
          element.imagen =
            process.env.VUE_APP_URL_IP_PUBLIC + result[index].award_image;
          element.acciones = result[index].award_id;
          this.datos.push(element);
        }
      });
      this.temp++;
    },
    /**
     * Función que se ejecuta cuando se pulsa el boton de crear premio
     * nos llevara al formulario para crear el premio.
     */
    addPremio() {
      this.$router.push("/premio/crearPremio/");
    },
  },
};
</script>